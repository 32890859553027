(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-quickplay2/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-quickplay2/assets/javascripts/render-component.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const isServer = typeof exports === 'object';

const trinidad = isServer ? require('trinidad-core').core : svs.core;

const getLogger = isServer ? trinidad.logger : svs.core.log.getLogger;
const logger = getLogger('banner_layouts:griditem-quickplay2');

const {
  resolveProductId,
  productIds,
  getDrawName,
  helpers
} = isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;

const {
  TTLCacheAsync
} = isServer ? require('../../../../lb-utils/ttl-cache/controller.js') : svs.components.lbUtils.ttlCache;

const {
  getProductBrand,
  getProductConfigData
} = isServer ? require('../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6') : svs.components.lbUtils.brandMapping.fn;

const {
  DrawState
} = isServer ? require('../../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6') : svs.components.sport.tipsenShared;
const ONE_MINUTE = 60 * 1000;
const TIMEOUT_FOR_JUPITER_CALLS = 3000;
const mockableJackpots = isServer ? trinidad.components.require('content', 'shared').jackpots : svs.content.shared;
const funds = isServer ? trinidad.components.require('lb-utils', 'funds').api : svs.components.lbUtils.funds;
const formatUtils = isServer ? trinidad.components.require('utils', 'format').api : svs.utils.format;
const {
  getProductSizes
} = isServer ? require('./product-sizes.es6') : svs.banner_layouts.griditem_quickplay2.product_sizes;
const enums = isServer ? require('../../../../marketplace-competition/constants/assets/javascripts/enum.es6') : svs.components.marketplaceCompetition.constants;
const Global = Object.seal({
  drawNumberCache: new TTLCacheAsync({
    onRefreshAsync: inputBag => _queryDraw(inputBag),
    ttl: ONE_MINUTE,
    onError: error => {
      logger.info("drawNumberCache.readAsync() rejected (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    },
    timeoutMS: TIMEOUT_FOR_JUPITER_CALLS
  })
});

async function renderComponent(bannerItem, widgetInfo, position, req, res) {
  var _bannerItem$images, _svs;
  if (!Object.keys(bannerItem.layoutConfig).length) {
    return '';
  }
  const {
    productAndDefaultSize,
    drawNumber: drawNumberConfig,
    ribbonText,
    layoutVariant,
    splashColor,
    title,
    bannerMainText
  } = bannerItem.layoutConfig;
  const [product, systemCostString] = String(productAndDefaultSize).split('-');
  const feature = svs.isServer ? req.feature : svs.core.detect.feature;
  const hvsClose = svs.isServer ? await feature('ft-hvs-close') : svs.core.detect.feature('ft-hvs-close');
  if (hvsClose && resolveProductId(product) === productIds.FULLTRAFF) {
    return '';
  }
  const systemCost = parseFloat(systemCostString.replace(',', '.'));
  const hasBlackText = String(layoutVariant).includes('black-text');
  const hasImage = String(layoutVariant).includes('with-image');
  const portraitImage = hasImage ? (_bannerItem$images = bannerItem.images) === null || _bannerItem$images === void 0 ? void 0 : _bannerItem$images.filter(item => item.typeName === 'productImage')[0] : null;
  const hbsCompiled = isServer ? global.internalInstances.get('hbs').cache['components-banner_layouts-griditem_quickplay2-item'] :
  svs.banner_layouts.griditem_quickplay2.templates.item;
  const isAdmin = isServer ?
  req.userSession.hasRole(req.userSession.roles.INTERNAL) :
  svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
  const trackingStringWithFixedStructure = [widgetInfo.widgetType, widgetInfo.trackingId || widgetInfo.widgetName, position, bannerItem.layoutType, bannerItem.title].join('/');
  const cachedData = (await Global.drawNumberCache.readAsync({
    key: "".concat(product, "_").concat(drawNumberConfig || 'auto'),
    payload: {
      product,
      drawNumberConfig,
      req
    }
  })) || {};
  const {
    productId,
    drawNumber,
    userProduct
  } = cachedData;
  const rowPrice = cachedData.rowPrice || 1;
  const productFunds = await Promise.all(cachedData.funds);
  const {
    jackpotAmountString,
    rollOverAndExtraMoney
  } = productFunds.find(fund => fund.drawNumber === drawNumber) || {};
  const {
    logoText,
    title: updatedTitle,
    typeOfSpeechBubble,
    speechBubbleRotation
  } = getDrawFundsSetup({
    jackpotAmountString,
    rollOverAndExtraMoney
  });
  const displayRibbonText = !typeOfSpeechBubble && typeof ribbonText === 'string' && ribbonText.trim() !== '';
  if (!productId || !drawNumber) {
    return '';
  }
  const productBrand = getProductBrand(productId, null, userProduct);
  const isMobile = isServer ? req.capabilities.formFactor === 'mobile' : svs.core.detect.formfactor.mobile();
  const systemType = String(enums.SystemType.MSys).toUpperCase();
  return hbsCompiled(_objectSpread(_objectSpread({}, bannerItem.layoutConfig), {}, {
    isAdmin,
    bannerId: bannerItem.id,
    branding: productBrand ? productBrand : product,
    features: isServer ? res.locals.features : (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features,
    productId,
    drawNumber,
    systemCost,
    hasBlackText,
    splashColor: splashColor === '-' ? null : splashColor,
    portraitImage,
    rowPrice,
    trackingString: trackingStringWithFixedStructure,
    crm: bannerItem.targetOffer,
    ribbonText,
    displayRibbonText,
    logoText,
    typeOfSpeechBubble,
    speechBubbleRotation,
    ribbonSize: isMobile ? 200 : 'responsive',
    jackpotAmountString,
    title: updatedTitle || title,
    bannerMainText,
    productSizes: getProductSizes(product),
    systemType
  })) || '';
}

async function _queryDraws(product, req) {
  const productId = resolveProductId(product);
  const path = "/draw/1/".concat(getDrawName(productId), "/draws");
  const queryString = '?include=draws.drawNumber%2Cdraws.regCloseTime%2Cdraws.productId%2Cdraws.rowPrice%2Cdraws.userProduct%2Cdraws.drawStateId';
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path: path + queryString,
    req
  });
  const {
    error,
    draws
  } = isServer ? response : response.response;
  if (error) {
    logger.info("API-error while listing current draws (product ".concat(productId, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return {};
  }
  if (!Array.isArray(draws) || draws.length === 0) {
    logger.info("Missing \"draws\" in response from draw-API (product ".concat(productId, ")"));
    return {};
  }
  const filteredDraws = draws.filter(draw => draw.drawStateId === DrawState.Opened);
  return {
    draws: filteredDraws
  };
}

async function getDrawFunds(_ref) {
  let {
    payload
  } = _ref;
  let jackpotAmountString;
  let currentFunds;
  let rollOverAndExtraMoney;
  let jackpots;
  let productConfig;
  let extraTipsetProduct;
  const {
    product,
    drawNumber,
    req
  } = payload;
  const productId = resolveProductId(product);
  if (productId === productIds.EUROPATIPSET) {
    productConfig = getProductConfigData(resolveProductId(productId));
    if (productConfig && !productConfig.unknownProduct) {
      extraTipsetProduct = productConfig.displayName;
    }
  }
  const jackpotProduct = extraTipsetProduct || product;
  try {
    jackpots = isServer ? await mockableJackpots.getJackpots({
      req,
      product: jackpotProduct
    }) : await mockableJackpots.getJackpots([jackpotProduct]);
  } catch (error) {
    logger.info('Error when fetching jackpots', error, req);
    return {};
  }
  if (Array.isArray(jackpots) && jackpots.length > 0) {
    const currentJackpot = drawNumber ? jackpots.find(jackpot => parseInt(jackpot.drawNumber, 10) === parseInt(drawNumber, 10)) : jackpots[0];
    jackpotAmountString = currentJackpot !== null && currentJackpot !== void 0 && currentJackpot.defaultJackpot ? null : currentJackpot === null || currentJackpot === void 0 ? void 0 : currentJackpot.jackpotAmountString;
  }
  if (!jackpotAmountString) {
    try {
      if (helpers.isTopptipset(productId)) {
        const products = ['topptipset', 'topptipsetstryk', 'topptipsetextra'];
        currentFunds = await funds.getFunds({
          req,
          products
        });
      } else {
        currentFunds = await funds.getFunds({
          req,
          product
        });
      }
    } catch (error) {
      logger.info('Error when fetching funds', error, req);
      return {};
    }
    if (Array.isArray(currentFunds) && currentFunds.length > 0) {
      const currentFund = drawNumber ? currentFunds.find(fund => fund.drawNumber === parseInt(drawNumber, 10)) : currentFunds[0];
      if (currentFund) {
        rollOverAndExtraMoney = currentFund.type === funds.fundsConstants.FUND_TYPE_ROLLOVER ? {
          type: currentFund.type,
          text: 'Rullpott!',
          fundsAmount: currentFund.fundsAmount
        } : {
          type: currentFund.type,
          text: "".concat(formatUtils.Currency(currentFund.fundsAmount, false), " kr extra!"),
          fundsAmount: currentFund.fundsAmount
        };
      }
    }
  }
  return {
    jackpotAmountString,
    rollOverAndExtraMoney,
    productId,
    drawNumber
  };
}
function getDrawFundsSetup(_ref2) {
  let {
    jackpotAmountString,
    rollOverAndExtraMoney
  } = _ref2;
  let logoText;
  let title;
  let typeOfSpeechBubble;
  let speechBubbleRotation;
  if (jackpotAmountString) {
    logoText = 'Jackpot!';
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_1';
    speechBubbleRotation = 'positive';
  } else if ((rollOverAndExtraMoney === null || rollOverAndExtraMoney === void 0 ? void 0 : rollOverAndExtraMoney.type) === funds.fundsConstants.FUND_TYPE_ROLLOVER) {
    logoText = rollOverAndExtraMoney.text;
    title = "".concat(formatUtils.Currency(rollOverAndExtraMoney.fundsAmount), " kr");
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_2';
    speechBubbleRotation = 'negative';
  } else if ((rollOverAndExtraMoney === null || rollOverAndExtraMoney === void 0 ? void 0 : rollOverAndExtraMoney.type) === funds.fundsConstants.FUND_TYPE_ADJUST) {
    logoText = rollOverAndExtraMoney.text;
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_3';
    speechBubbleRotation = 'negative';
  }
  return {
    logoText,
    title,
    typeOfSpeechBubble,
    speechBubbleRotation
  };
}

async function _queryDraw(_ref3) {
  let {
    payload
  } = _ref3;
  const {
    product,
    req,
    drawNumberConfig
  } = payload;
  const {
    draws
  } = await _queryDraws(product, req);
  if (!draws || draws.length === 0) {
    return {};
  }
  let earliestRegCloseTimeTS = null;
  let dataProductId;
  let rowPrice;
  let userProduct;
  let drawNumber;
  const funds = [];
  draws.forEach(item => {
    let isPossiblyWantedItem = false;

    if (drawNumberConfig && parseInt(drawNumberConfig, 10) === item.drawNumber) {
      isPossiblyWantedItem = true;
    }
    if (!drawNumberConfig && item.regCloseTime) {
      const regCloseTimeTS = new Date(item.regCloseTime).getTime();
      const hasEarlierRegCloseTime = earliestRegCloseTimeTS === null || regCloseTimeTS < earliestRegCloseTimeTS;
      if (hasEarlierRegCloseTime) {
        earliestRegCloseTimeTS = regCloseTimeTS;
        isPossiblyWantedItem = true;
      }
    }
    if (isPossiblyWantedItem) {
      drawNumber = item.drawNumber;
      dataProductId = item.productId;
      rowPrice = parseFloat(String(item.rowPrice).replace(',', '.'));
      userProduct = item.userProduct;
    }
    funds.push(getDrawFunds({
      payload: {
        product,
        drawNumber: item.drawNumber,
        req
      }
    }));
  });
  return {
    drawNumber,
    productId: dataProductId,
    rowPrice,
    userProduct,
    funds
  };
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  setGlobal('svs.banner_layouts.griditem_quickplay2', {
    renderComponent
  });

}

 })(window);